<template>
  <div class="row" style="background: #1f1e1f">
    <div class="col-xl-8 col-sm-12">
      <div class="col-sm-12 col-lg-8 offset-lg-2 col-xl-10 offset-xl-1">
        <article class="card">
          <div class="header text-smart" style="margin-bottom: 10px">

            <HeadImage :image="mainImage"
                       :clamp="true"
                       align="text-center"
                       title=" LOS CABOS NIGHTLIFE" caption="Top Things To Do At Night " color="#db9454"></HeadImage>

            <br/>
            <p >
              Los Cabos isn't just about sun and sand. When the sun dips below
              the horizon, the magic truly begins.
              <strong>Discover the vibrant nightlife scene</strong> with our
              guide to the <strong>top things to do after dark.</strong>
            </p>
          </div>

          <div>
            <div class="text-center">
              <div class="row">
                <div class="col-sm-12">
                  <img-card
                    :image="ballenaImg"
                    align="center"
                    alt="cabo big ballena"
                    height="200px"
                    width="200px"
                    sub-title="La Marina At Night"
                    text="<p>Cabo offers something for everyone. Discover your perfect Cabo adventure with our guide below!</p>
"
                  />

                </div>
              </div>
            </div>
            <section>
              <h3 class="section-header">WHAT CAN YOU FIND?</h3>
              <p>
                Los Cabos offers a variety of nighttime activities, including <strong>late beach clubs,
                festive restaurants</strong> for late dinners, <strong>dance clubs</strong>, nightclubs, parties, and occasional
                <strong>big festivals</strong>, catering to all tastes.
              </p>
              <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
              <br>
              <h4 class="section-header">Top Restaurants</h4>
              <p>Festive restaurants offer a captivating blend of <strong>delicious food, refreshing drinks</strong>, and <strong>exciting</strong> entertainment.
                <br>
                <br>
                <br>
              </p>
              <div class="diego-hero">
                <p style="margin-top: -30px; padding: 4px;">
                  With a range of options to <strong>suit every budget and taste.</strong>
                </p>
              </div>
              <br>
              <div class="">
                <img-card
                  title="Rosa Negra"
                  align="center"
                  class="centered"
                  width="200"
                  height="200"
                  sub-title="Latin-inspired dinner and fire shows."
                  image="https://imgix.cosmicjs.com/032761d0-01ac-11ef-bec5-7f9403a32f29-rosa-negra-los-cabos-8.jpg"
                ></img-card>

                <ul>
                  <li>
                    <router-link
                      :to="{ path: '/los-cabos-clubs/rosa-negra-los-cabos' }"
                      class=""
                    >Rosa Negra
                    </router-link>
                    : Latin-inspired dinner and <strong>fire show</strong>.
                  </li>
                  <li>
                    <router-link
                      :to="{ path: '/los-cabos-clubs/taboo-los-cabos' }"
                    >Taboo
                    </router-link>
                    : Beach Club &amp; restaurant with <strong>lively happenings</strong>.
                  </li>
                  <li>
                    <router-link
                      :to="{ path: '/los-cabos-clubs/chambao-los-cabos' }"
                    >Chambao
                    </router-link>
                    : Fashion-inspired grill house &amp; restaurant where
                    <strong>exclusive beef cuts</strong> and seafood delicacies are
                    distinguished.
                  </li>
                  <li>
                    <a href="https://mamazzita.com.mx/">
                      Mamazzita
                    </a>: Culinary concept with a festive and <strong>colorful atmosphere</strong>
                    that reflects the Mexican essence at its best.
                  </li>
                  <li>
                    <a href="https://funkygeisha.com.mx/en/asian-restaurant-in-los-cabos">Funky Geisha</a>
                    : Asian Restaurant with stunning sea views and <strong>Fresh Asian
                    Cuisine.</strong>
                  </li>
                </ul>
                <p>
                  Also, you can check out our special&nbsp;
                  <router-link
                    :to="{path: '/los-cabos-dinner-table-reservations' }">
                    dinner section</router-link>.
                </p>
              </div>
            </section>
            <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
            <br>
            <section>
              <h4 class="section-header">Top Clubs</h4>
              <div class="text-center centered">
                <p style="text-align: left;">
                  <strong>Plenty of Clubs</strong> are located on both San Jose & Cabo San Lucas.
                  <br>
                  <br>
                  <br>
                </p>
                <div class="diego-hero">
                  <p style="margin-top: -30px; padding: 4px;">
                    <strong>Mexican bars, Dance Clubs & Elegant Clubs with Shows</strong>
                  </p>
                </div>
                <br>
                <img-card
                  title="El Squid Roe"
                  sub-title="World Famous Since 1989"
                  align="center"
                  width="200"
                  height="200"
                  image="https://imgix.cosmicjs.com/9db45830-4909-11ef-b1ea-f56c65dfade9-El_Squid_Roe_Nightclub.jpg"
                ></img-card>
              </div>
              <ul>
                <li>
                  <a href="https://www.elsquidroe.com/">El Squid Roe</a>:
                  World-famous El Squid Roe is a three-story party venue known for its <strong>unique style,
                  funny decor, and delicious food</strong>, located in the heart of Cabo.
                </li>
                <li>
                  <router-link
                    :to="{path: '/los-cabos-clubs/crania-los-cabos'}">Crania
                  </router-link>:
                  Innovative Restaurant &amp; Air Disco Nightclub with <strong>Bold
                  Flavours and Stunning Cranes.</strong>
                </li>
                <li>
                  <router-link
                    :to="{path: '/los-cabos-clubs/taboo-los-cabos'}">Taboo
                  </router-link> Gourmet
                  Dining, Latin Music, and <strong>Captivating Live Shows</strong>.&nbsp;
                </li>
                <li>
                  <router-link
                    :to="{path: '/los-cabos-clubs/bagatelle-los-cabos'}">Bagatelle
                  </router-link>
                  French Mediterranean Cuisine, Dinner Shows, and <strong> Luxurious
                  Beachfront Vibes.</strong>
                </li>
                <li>
                  <a href="https://grupomandala.com.mx/cabos.html"
                  >Grupo Mandala</a
                  >
                  Party Club &amp; Bar.
                </li>
                <li>
                  <a href="https://www.mangodeckcabo.com/">Mango Deck</a> Beach
                  Club, Bar, Restaurant with <strong>Traditional Mexican Food</strong>.
                </li>
              </ul>
              <div class="text-center centered">
                <MiniCarrousel
                  title="Stay Awake And Fade Away"
                  folder="cabo-nightlife-media"
                  num-photos="4"
                ></MiniCarrousel>
              </div>
              <p>
                For more info, you can always check out our
                <router-link
                  :to="{path:'/los-cabos-clubs'}">
                  &nbsp;exclusive club page.</router-link>
              </p>
            </section>
            <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
            <br>
            <section>
              <h4 class="section-header">Parties & Festivals</h4>
              <p>
                <strong>High-quality festivals</strong> and parties pop up regularly throughout Cabo.
              </p>
              <br>
              <br>
              <div class="diego-hero">
                <p style="margin-top: -30px; padding: 4px; text-align: center">
                  <strong>Keep an eye out for upcoming events.</strong>
                </p>
              </div>
              <img-card
                title="Sundream Baja"
                sub-title="A dream under the sun"
                align="center"
                width="200"
                height="200"
                image="https://imgix.cosmicjs.com/8db98e30-4a28-11ef-b1ea-f56c65dfade9-SUNDREAM___sundream.jpg"
              ></img-card>
              <ul>
                <li><a href="https://www.sundreamofficial.com/">Sundream Baja:</a>
                  A destination event curated by Rufus Du Sol
                </li>
                <li>
                  <router-link
                    :to="{path:'/event/dance-with-me-art-with-me-crania-los-cabos-march-31-2024'}">
                    Art With Me</router-link>: An international <strong>arts, music and cultural festival</strong>, offering experiences across
                  the globe.

                </li>
                <li>Mayan Warrior: A <strong>techno-house festival</strong> organized by the Homonimus art collective,
                  which was originally formed for the Burning Man Festival.
                </li>
              </ul>
              <div class="text-center centered">
                <MiniCarrousel
                  title="Explosive Parties & Festivals"
                  folder="cabo-nightlife-festivals"
                  num-photos="4"
                ></MiniCarrousel>
              </div>
            </section>
            <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
            <br>
            <section>
              <h3 class="section-header">WHEN?</h3>
              <p>
                Cabo's activity level varies significantly with the seasons, becoming a lively hub
                during peak times and a relaxed oasis in the off-season.
              </p>
              <br>
              <br>
              <div class="diego-hero">
                <p style="margin-top: -30px; padding: 4px; text-align: center">
                  Weekly activities typically follow a consistent pattern, with <strong>most party events occurring on weekends and Mondays.</strong>
                </p>
              </div>
              <!-- Semana de actividades -->
              <div class="col-sm-12">
                <br>
                <h4 class="section-header" style="background: #d4bd93 !important">
                  Monday
                </h4>
                <br>
                <p style="text-align: center;">
                  On Monday afternoons, enjoy Latin rhythms after an extraordinary dinner at Rosa Negra,
                  and then relive the night with your friends and House music at Bagatelle.
                </p>
                <!--Agregar links de restaurantes -->
              </div>
              <div class="col-sm-12">
                <br>
                <h4 class="section-header" style="background: #d4ab52 !important">
                  Tuesday
                </h4>
                <br>
                <p style="text-align: center;">
                  Start Tuesday with a pool party and Latin flavors at Taboo Cabo, where you’ll find iconic
                  live shows featuring saxophone and violin. In the evening, enjoy a combination of a show and
                  dinner at Chambao Los Cabos, where you can witness the culinary scene in Los Cabos.
                </p>
                <!--Agregar links de restaurantes -->
              </div>
              <div class="col-sm-12">
                <br>
                <h4 class="section-header" style="background: #e58e76 !important">
                  Wednesday
                </h4>
                <br>
                <p style="text-align: center;">

                  Wednesdays are known for the shows they offer. Enjoy a fire show at Rosa Negra,
                  guitar melodies at Chambao Los Cabos, or the classic mid-week events at Taboo Los Cabos.
                  Whatever you choose, you can’t miss out on the fun!
                </p>
                <!--Agregar links de restaurantes -->

                <br>
              </div>
              <div class="col-sm-12">
                <h4 class="section-header" style="background: #e07354 !important;">
                  Thursday
                </h4>

                <p style="text-align: center;">
                  <br>
                  Thursdays belong to Bagatelle, a Mediterranean French restaurant that promises you an
                  unforgettable experience during the day with its themed events and iconic dishes.
                  The night is all about festivals here, and they know how to make sure you have the best night!
                </p>
                <!--Agregar links de restaurantes -->

                <br>
              </div>
              <div class="col-sm-12">
                <h4 class="section-header" style="background: #de5021 !important">
                  Friday
                </h4>
                <br>
                <p style="text-align: center;">
                  Before diving into the weekend adventures, enjoy delicious signature dishes from
                  Craft Los Cabos at an outdoor fire kitchen. Ignite the night with your friends at
                  Taboo Los Cabos, where Latin rhythms and memorable scenes await.
                </p>
                <!--Agregar links de restaurantes -->
                <br>
              </div>
              <div class="col-sm-12">
                <h4 class="section-header" style="background: #e54815 !important">
                  Saturday
                </h4>
                <br>
                <p style="text-align: center;">
                  The weekend has begun, and it's time to bring the party to Los Cabos.
                  Head back to Taboo Los Cabos to enjoy the beach and Latin rhythms alongside artists like Hugel,
                  Solardo, or Claptone. As night falls, try something different at Mamazzita Los Cabos and let
                  yourself be carried away by the salsa nights.
                </p>
                <!--Agregar links de restaurantes -->
                <br>
              </div>
              <div class="col-sm-12">
                <h4 class="section-header" style="background: #d29074 !important">
                  Sunday
                </h4>
                <br>
                <p style="text-align: center;">
                  Revive your Sunday with a brunch at Bagatelle, featuring delicious Mediterranean flavors,
                  and then take some time to relax. In the evening, enjoy a memorable dinner at Chambao Los Cabos.
                </p>
                <!--Agregar links de restaurantes -->
                <br>
              </div>
            </section>
            <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
            <section>
              <h3 class="section-header">WHERE?</h3>
              <p>
                Los Cabos is divided. Both Cabo and Baja in
                general, refer to actually two places:
                <router-link
                  :to="{path: '/los-cabos-clubs/crania-los-cabos'}"
                >San Jose del Cabo</router-link
                >
                and
                <a
                  href="https://www.cabo.party/los-cabos-clubs/taboo-los-cabos"
                  rel="noopener noreferrer"
                >Cabo San Lucas</a
                >, separated by less than 20 miles.
              </p>
              <br>
              <div dir="ltr">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>Place</th>
                    <th>Vibe</th>
                    <th>Activities</th>
                    <th>Food</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr data-sourcepos="6:1-6:106">
                    <td
                      class="fr-thick"

                    >
                      <p><strong>CABO SAN LUCAS</strong></p>
                    </td>
                    <td>
                        <span style="font-size: 11px"
                        >Lively, party-oriented &amp; tourist-centric</span
                        >
                    </td>
                    <td>
                        <span style="font-size: 11px"
                        >Nightlife, water activities, resorts</span
                        >
                    </td>
                    <td>
                        <span style="font-size: 11px"
                        >High-end restaurants</span
                        >
                    </td>
                  </tr>
                  <tr>
                    <p><strong>SAN JOSE DEL CABO</strong></p>
                    <td>
                        <span style="font-size: 11px"
                        >Relaxed, artsy &amp; Cultural</span
                        >
                    </td>
                    <td>
                        <span style="font-size: 11px"
                        >Art, natural reserves, beaches</span
                        >
                    </td>
                    <td>
                        <span style="font-size: 11px"
                        >Authentic Mexcian cuisine</span
                        >
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <br>
              <br>
              <div class="diego-hero">
                <p style="margin-top: -30px; padding: 4px; text-align: center">
                  <strong>Affordable</strong> places tend to be near the <strong>center of town</strong> and the
                  <strong>luxurious</strong> ones tend to be in the <strong>beach zone</strong>.
                </p>
              </div>
              <p>
                However, you can find something that accommodates your party plans.&nbsp;
              </p>

              <p>
                If you don’t know where to start, you can<strong
              >&nbsp;always contact us and check our </strong
              ><router-link
                :to="{path: '/los-cabos-mexico-event-calendar?period=this_week'}"
              ><strong>daily updated calendar</strong></router-link
              >
                for <strong>personalized</strong> recommendations and the latest events happening
                in Los Cabos.&nbsp;
              </p>
            </section>
          </div>
          <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
          <h4 class="section-header"> PHOTOS </h4>
          <Carrousel folder="cabo-nightlife-media" color="#020303"></Carrousel>

          <SmartMenu withRsvp="false" :links="links">
            <p>Unlock Los Cabos Magic - Secure your spot with us now!</p>
          </SmartMenu>

        </article>
      </div>
    </div>

    <div class="card col-4 col-lg-4 d-none d-lg-inline">
      <br/>
      <h4 class="section-header" style="font-weight: bold">
        <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
      </h4>
      <div class="row">
        <div class="col-sm-12 text-center">
          <p><strong>Feeling Lost?</strong></p>
          <hr/>
          <p>
            Let us be your guide. Discover the best party & events along the way.
          </p>
          <hr/>
        </div>
        <div class="col-sm-12 text-center">
          <RsvpViaDialog text="CONTACT US"></RsvpViaDialog>
          <hr/>
          <small>NO EXTRA FEE</small>
        </div>
      </div>
      <br/>


      <section
      >
        <h4 class="section-header">> RESOURCES</h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <router-link :to="{ path: '/los-cabos-mexico-event-calendar' }">Calendar</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-clubs' }">Clubs</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-dinner-table-reservations' }">Dinner Reservations</router-link>
          </li>
        </ul>
      </section>
      <section
      >
        <h4 class="section-header">> Explore</h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <router-link :to="{ path: '/los-cabos-clubs/taboo-los-cabos' }">Taboo Los Cabos</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-shorts/fire-shows-rosa-negra-los-cabos-video-youtube'}">Rosa Negra Fire Show</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-shorts/rufus-du-sol-sundream-festival-los-cabos-video'}">Rufus Du Sol</router-link>
          </li>
        </ul>
      </section>

      <section
      >
        <h4 class="section-header">
          > CONNECT
        </h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <a href="https://www.instagram.com/cabo.party/" target="_blank"
            >Instagram</a
            >
          </li>
        </ul>
      </section>
      <DynamicLinks :links="links"></DynamicLinks>
    </div>
  </div>
</template>

<script>
import ImgCard from '@/components/Cards/ImgCard';
import Carrousel from '@/components/Carrousel';
import DynamicLinks from '@/components/DynamicLinks';
import MiniCarrousel from '@/components/MiniCarrousel';
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import HeadImage from "@/components/HeadImage/HeadImage";
import SmartMenu from "@/components/SmartMenu/SmartMenu";


export default {
  name: 'CaboNightLife',
  created: function () {
    setTimeout(() => (this.showPalms = true), 5000);
    this.$store.dispatch('getLinks', {slug: 'los-cabos-nightlife'});
  },
  props: ['slug'],
  metaInfo() {
    return {
      title: `Los Cabos NightLife Guide - Top Things To Do`,
      meta: [
        {
          name: 'description',
          content: `Los Cabos. Top Things To Do At Night.`,
        },
        {
          name: 'keywords',
          content: `Los Cabos Nightlife, Nightclubs, Bars, Downtown, Party Zone,`,
        },
        {
          property: 'og:title',
          content: `Los Cabos NightLife. Top Things To Do.`,
        },
        {
          property: 'og:url',
          content: `https://cabo.party/los-cabos-nightlife`,
        },
        {
          property: 'og:description',
          content: `Los Cabos NightLife. Top Things To Do At Night.`,
        },
        {property: 'og:type', content: 'article'},
        {property: 'og:locality', content: 'Tulum'},
        {property: 'og:country-name', content: 'Mexico'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/df7ff070-76a4-11eb-96b6-e94a2ed95964-zamna.png',
        },
      ],
    };
  },
  data: () => {
    return {
      color: '#535353',
      ballenaImg:
        'https://imgix.cosmicjs.com/f53ef140-4a40-11ef-b1ea-f56c65dfade9-cabo-marina.jpg',
      mainImage:
        'https://imgix.cosmicjs.com/5b93fb90-2aa5-11ef-adb1-8b946b3a80e4-tale-of-us-sundream-baja.png',
    };
  },
  components: {
    HeadImage,
    RsvpViaDialog,
    ImgCard,
    MiniCarrousel,
    Carrousel,
    DynamicLinks,
    SmartMenu,
    // Carrousel,
    // ImgCard,
  },
  methods: {},
  computed: {
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
  },
};
</script>
